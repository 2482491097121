<template>
	<div>
		<CarbarButton :is-full-width="false" @click="showSellMyCar">
			{{ buttonText }}
		</CarbarButton>

		<ClientOnly>
			<LazyModalsFormModal
				:show="showInstantOfferModal"
				@closed="closeWidget"
			>
				<template #bookingForm>
					<LazySellMyCarWidgetNew
						v-if="showInstantOfferModal"
						ref="sellMyCarWidgetRef"
						class="px-4 sm:px-0"
					/>
				</template>
			</LazyModalsFormModal>
		</ClientOnly>
	</div>
</template>

<script setup>
import CarbarButton from '@/components/buttons/CarbarButton.vue'

const props = defineProps({
	buttonText: {
		type: String,
		default: 'Get an instant valuation',
		required: false,
	},
})

const showInstantOfferModal = ref(false)
const sellMyCarWidgetRef = ref(null)

const launchWidget = () => {
	showInstantOfferModal.value = true
}

const closeWidget = () => {
	showInstantOfferModal.value = false
	sellMyCarWidgetRef.value.resetWidget()
}

const showSellMyCar = () => {
	launchWidget()
}
</script>
